<template>
  <div all-product>
    <Visual2 tit="PRODUCT" txt="현대제철이 만든<br class='mo'> 프리미엄 강재<br> <b>안</b>전을 <b>심</b>은 철,<br class='mo'> <b>안심</b> <i class='kv-logo'></i>" bg="product-kv"/>
    <div class="tab" :class="{open:isOpen}" @click="listToggle">
      <ul>
        <li class="active"><RouterLink to="/product">All Product</RouterLink></li>
        <li><RouterLink to="/section">SECTION</RouterLink></li>
        <li><RouterLink to="/bar">REINFORCING BAR</RouterLink></li>
        <li><RouterLink to="/plate">PLATE</RouterLink></li>
        <li><RouterLink to="/pipe">STEEL PIPE</RouterLink></li>
        <li><RouterLink to="/hrcr">HR | CR</RouterLink></li>
      </ul>
    </div>
    <article>
      <Paragraph class="all-content">
        <div class="top para">
          <p><b>최고의 품질</b>을 자랑하는<br><i class="vk-logo"></i> 의<br class="mo"> 다양한 제품을 만나보세요.</p>
        </div>
        <div class="cont para" v-for="(prod, idx) in prodList" :key="prod.item" :class="'prod'+(idx+1)">
          <div class="anchor" :id="'prod'+(idx+1)"></div>
          <div class="cnt-inner">
            <p class="nm">{{ prod.nm }}</p>
            <p class="tit" v-html="prod.tit"></p>
            <p class="txt" v-html="prod.txt"></p>
            <div class="type" v-html="prod.type" :class="'type'+(idx+1)"></div>
          </div>
          <Slider :class="'slide-cont'+(idx+1)" navigation slides-per-view="auto">
            <div class="swiper-slide" v-for="(item, num) in prod.slideList" :key="item" :class="'slide'+(idx+1)+'-'+(num+1)">
              <img :src="slideImgPath+(idx+1)+'-'+(num+1)+'.png'">
            </div>
          </Slider>
          <a class="qna" @click="contactModal(prod);">1:1 문의하기 <i></i></a>
        </div>
      </Paragraph>
    </article>
  </div>
</template>

<script>
import Visual2 from "@/views/components/Visual2";
import Paragraph from "@/views/components/Paragraph";
import Slider from "@/views/components/Slider";
import product from "@/data/product";
import ContactModal from "@/views/components/modal/ContactModal";

export default {
  name: "AllProduct",
  components: {Visual2, Paragraph, Slider },
  data() {
    return {
      slideImgPath: '/images/pc/product-prd',
      winWidth: 0,
      isOpen:  false,
    }
  },
  computed: {
    prodList() {
      return product.prodList;
    }
  },
  methods: {
    contactModal(prod) {
      console.log(prod)
      this.$modal(ContactModal, {category:prod.prd});
    },
    listToggle() {
      this.isOpen = !this.isOpen
    },
  }
}
</script>

<style lang="less">
@import "~@/less/proj";
@use-rem: true;
@rem: 32;
[all-product] {
  .tab { transform: translateY(0); transition: transform 0.5s, opacity 0.5s; .ease-out; .bgc(#fff);
    ul { .tc; .rel; .h(101); overflow: hidden; transition: height 0.2s; .ease-out; .rel;
      &:after { .cnt; .wh(53,29); .contain('/images/mo/ico-tab-arrow.png'); .abs; .rt(48,38); }
      li { .fs(30); .lh(70); .bold; .ls(-0.025em); .pointer; .spoqa; .-b(#333); .-box; .rel; .lt(0,100); .wf; color:#0d1112; transition: opacity 0.3s;
        a { .f; .ib; }
        &.active { .fs(36); .lh(100); .abs; .lt; }
        &:hover { .o(1); }
      }
    }
    &.open ul { .h(388);
      &.sup-tabs { .h(315); }
      &:after { transform: rotate(180deg); }
    }
    &.ready { transform: translateY(100px); opacity: 0; }
  }
  article { .bgc(#fff); .rel; .crop;
    .all-content {
      .top { .p(110,0,85); .-box;
        p { .fs(48); .lh(60); .tc; .ggo;
          .vk-logo { .wh(266,57); .contain('/images/mo/logo-blue.png'); .ib; .vam; .mt(-10); }
        }
      }
      .cont { .tl; .rel; .pb(130); .-box;
        .anchor { .abs; .lt(0,-200); }
        .cnt-inner { .p(116,0,0,50); .-box; .rel; z-index: 1;
          .nm { .fs(72); .lh(84); .roboc; .bold; text-transform: uppercase; }
          .tit { .fs(36); .lh(48); .ggo;
            b { .bold; }
          }
          .txt { .fs(20); .lh(30); .ls(-0.05em); .spoqa; .mt(40); }
          .type { .w(660); .crop; .mt(50);
            span { .fs(26); .lh(44); .ggo; .medium; .-b(#333,3); .ib; .vat; .min-w(300); .m(0,30,20,0);
              a { .f; .ib; }
              &:nth-child(2n) { .mr(0); }
            }
            &.type1 { .flex; .space-between; .flex-wrap;
              span.sec-h { .min-w(250); }
            }
          }
        }
        [slider] { .rel; .mt(70);
          .swiper-container { .pl(50); .mr(50); .-box;
            .swiper-slide { .wh(400,250); .mr(20);
              &:last-child { .mr(0); }
              img { .f; .ib; }
            }
          }
          .swiper-navigation {
            .swiper-btn { .wh(43,81); .ib; .o(1);
              &:after { .hide; }
              &.swiper-button-prev { .contain('/images/mo/ico-prev.png'); }
              &.swiper-button-next { .contain('/images/mo/ico-next.png'); }
            }
          }
        }
        .qna { .fs(24); .lh(60); .wh(300,60); .tc; .spoqa; .m(40,0,0,50); .-a(#333); .-box; .ib; transition: background-color 0.3s, transform 0.5s, opacity 0.5s; .ease-out;
          i { .wh(49,12); .contain('/images/mo/ico-prod-qna-arrow.png'); .ib; .vam; pointer-events: none; .ml(10); }
          &:hover { .bgc(#153347); color:#fff;
            i { filter: invert(1); }
          }
        }
        &.prod1:after { .cnt; .wh(375,414); .contain('/images/mo/prd-cont1.png'); .abs; .rt; }
        &.prod2:after { .cnt; .wh(431,543); .contain('/images/mo/prd-cont2.png'); .abs; .rt; }
        &.prod3:after { .cnt; .wh(375,297); .contain('/images/mo/prd-cont3.png'); .abs; .rt; }
        &.prod4:after { .cnt; .wh(326,372); .contain('/images/mo/prd-cont4.png'); .abs; .rt; }
        &.prod5:after { .cnt; .wh(420,373); .contain('/images/mo/prd-cont5.png'); .abs; .rt; }
      }
      .prod1, .prod3, .prod5 { .bgc(#f2f2f2);}
    }
  }
}
@media screen and(min-width: 1024px) {
  [all-product] {
    .tab {
      ul { .max-w(1400); .mh-c; .h(85); .-b; .flex; .space-between;
        &:after { .cnt; .wh(100%,1); .bgc(#d8d8d8); .abs; .lb(0,5); }
        li { .fs(24); .lh(85); .ib; width:auto; .p(0,50); .-box; .vam; .o(0.2); .rel; left:auto; top:auto; .-b;
          &.active { .fs(24); .lh(85); .o(1); left:auto; top:auto; .rel; .c(#0079ae);
            &:after { .cnt; .wh(100%,5); .bgc(#0079ae); .abs; .lb; }
          }
        }
      }
      &.open ul { height: auto;
        &.sup-tabs { height: auto; }
      }
    }
    article {
      .all-content {
        .top { .p(150,0,120);
          p { .fs(60); .lh(80); }
        }
        .cont { .pb(215);
          .cnt-inner { .p(120,0,0,70); .w(1240); .mh-c;
            .type { .w(1050); .mt(50); .tl;
              span {
                &:nth-child(2n) { .mr(30); }
                &:nth-child(3n) { .mr(0); }
              }
              &.type1 { .ib;
                span {
                  &.sec-h { .min-w(300); }
                  &.sec-b { .min-w(360); }
                }
              }
              &.type4 { .wf; }
            }
          }
          [slider] { .w(1240); .mh-c; .mt(30);
            .swiper-container { .pl(70); .mr(0); pointer-events: none; }
            .swiper-navigation { .hide; }
          }
          .qna { .fs(18); .lh(50); .wh(300,50); .abs; .lb(50%,125); .ml(-550); }
          &.prod1:after { .cnt; .wh(631,475); .contain('/images/pc/prd-cont1.png'); .abs; .rt; }
          &.prod2 {
            &:after { .cnt; .wh(693,378); .contain('/images/pc/prd-cont2.png'); .abs; .rt; }
            .cnt-inner .type span {
              &:nth-child(1), &:nth-child(3) { .w(420); .mr(30); }
            }
          }
          &.prod3:after { .cnt; .wh(523,544); .contain('/images/pc/prd-cont3.png'); .abs; .rt; }
          &.prod4:after { .cnt; .wh(618,441); .contain('/images/pc/prd-cont4.png'); .abs; .rt; }
          &.prod5:after { .cnt; .wh(536,550); .contain('/images/pc/prd-cont5.png'); .abs; .rt; }
        }
      }
    }
  }
}
</style>