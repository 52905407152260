<template>
  <div slider>
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <slot/>
      </div>
    </div>
    <div class="swiper-pagination" v-if="pagination"></div>
    <div class="swiper-navigation" v-if="navigation">
      <a class="swiper-button-prev swiper-btn"/>
      <a class="swiper-button-next swiper-btn"/>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper/swiper-bundle.min';

export default {
  name: 'Slider',
  props: {
    speed: {type: Number, default: 700 },
    slidesPerView: { type: String, default: undefined },
    autoplay: {type: Boolean, default: false},
    loop: { type: Boolean, default: false },
    freeMode: { type:Boolean, default: false },
    pagination: { type: Boolean, default: false },
    paginationType: { type: String, default: 'bullets' },
    navigation: { type: Boolean, default: false },
    option: Object,
  },
  data() {
    return {
      slider: null,
      opt: {},
      timer: null,
    };
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    if (this.slider) this.slider.destroy();
  },
  methods: {
    init() {
      if (this.slidesPerView) {
        this.opt.slidesPerView = this.slidesPerView;
      }
      if (this.autoplay) {
        this.opt.autoplay = { delay: 5000 };
      }
      if (this.freeMode) {
        this.opt.freeMode = true;
      }
      if (this.navigation) {
        this.opt.navigation = {
          nextEl: this.$el.querySelector('.swiper-button-next'),
          prevEl: this.$el.querySelector('.swiper-button-prev'),
        };
      }
      this.slider = new Swiper(
          this.$el.querySelector('.swiper-container'),
          this.opt,
      );
    },
  },
};

</script>